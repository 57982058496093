export function initHelpButton() {
  const helpButton = document.querySelector(".help--button")
  const footer = document.querySelector(".footer")
  if (helpButton && footer) {
    document.addEventListener("scroll", (e) => {
      const intersected =
        footer.getBoundingClientRect().y <
        helpButton.getBoundingClientRect().y + helpButton.clientHeight
      toggleHelpButton(intersected, helpButton)
    })
  }
}

export function toggleHelpButton(intersected, _helpButton) {
  const helpButton = _helpButton || document.querySelector(".help--button")
  helpButton.classList.toggle("intersected", intersected)
}
