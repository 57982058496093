import { toggleHelpButton } from "./helpButton"

const $ = window.Bliss
const $$ = $.$

export class OverlayHandler {
  constructor(el) {
    this.el = el
    this.closeButton = $("[data-overlay-toggle]")
    this.helpButton = $(".help--button")
    // this.changeButton = $("[data-overlay-change-level]", this.el)
    // this.changeButtonText = $("[data-overlay-change-text]", this.changeButton)
    this.overlayMenu = $("[data-overlay-menu]", this.el)
    this.overlayMenuParent = $$("[data-overlay-menu-parent]", this.el)
    this.overlayContent = $("[data-overlay-container]", this.el)

    this.activeLevel = false
    this.isOpen = false
    this.overlayMenuViewer = $("[data-overlay-menu-viewer]", this.el)
    this.children = $$("[data-overlay-menu-children]")

    this.addListeners()
  }

  addListeners = () => {
    const mediaQuery = window.matchMedia("(max-width: 750px)")

    if (mediaQuery.matches) {
      for (const el of this.overlayMenuParent) {
        if (el.getAttribute("data-overlay-has-children")) {
          el.dataset.overlayAccordion = "true"
        }
      }
    }

    window.addEventListener("resize", () => {
      if (mediaQuery.matches) {
        for (const el of this.overlayMenuParent) {
          if (el.getAttribute("data-overlay-has-children")) {
            el.dataset.overlayAccordion = "true"
          }
        }
      } else {
        for (const el of $(
          "[data-overlay-content-level='first']",
        ).querySelectorAll("[data-overlay-menu-parent]"))
          el.dataset.overlayAccordion = "false"
      }
    })

    document.body.addEventListener("click", (e) => {
      const link = e.target.closest(
        'a[data-overlay-level="first"], a[data-overlay-level="second"]',
      )

      if (link && !this.isOpen) {
        this.overlayLevel = link.dataset.overlayLevel
        e.preventDefault()
        this.openOverlay(this.overlayLevel)
        this.activeLevel = this.overlayLevel
        this.closeButton.dataset.overlayToggle = "close"
        this.isOpen = true
      } else if (
        link &&
        this.isOpen &&
        e.target.closest("[data-help-cursor]")
      ) {
        this.overlayLevel = link.dataset.overlayLevel
        e.preventDefault()
        this.openOverlay(this.overlayLevel)
        this.activeLevel = this.overlayLevel
      } else if (
        !e.target.closest("[data-overlay-container]") &&
        !e.target.closest("[data-toggle]") &&
        this.isOpen
      ) {
        e.preventDefault()
        this.isOpen = false
        this.closeButton.dataset.overlayToggle = "open"
        this.close()
      }
    })

    // this.changeButton.addEventListener("click", (e) => {
    //   e.preventDefault()
    //   this.changeLevel()
    // })

    for (const link of this.overlayMenuParent) {
      const overlayAccordion = link.dataset.overlayAccordion

      const child = $("[data-overlay-menu-children]", link)
      const childToggle = $(".navigation--item-link", link)

      if (child && overlayAccordion === "false") {
        this.overlayMenuViewer.appendChild(child)
      }

      if (overlayAccordion === "false" && !mediaQuery.matches) {
        link.addEventListener("click", () => {
          // link.style.opacity = '0.5'
          // this.overlayMenuViewer

          for (const child of this.children) {
            child.classList.remove("active")
          }

          for (const child of this.overlayMenuParent) {
            child.classList.remove("active")
          }

          if (child) {
            child.classList.add("active")
            link.classList.add("active")
            this.overlayMenu.parentNode.classList.add("active--token")
          } else {
            this.overlayMenu.parentNode.classList.remove("active--token")
          }
        })
      } else {
        childToggle.addEventListener("click", () => {
          // this.children.forEach((child) => child.classList.remove("active"))
          //
          for (const child of this.overlayMenuParent) {
            child.classList.remove("active")
          }
          if (child) {
            if (child.classList.contains("active")) {
              child.classList.remove("active")
              link.classList.remove("active")
              this.overlayMenu.parentNode.classList.remove("active--token")
            } else {
              // let childHeight = child.offsetHeight
              // console.log(childHeight);
              for (const child of this.children) {
                child.classList.remove("active")
              }
              child.classList.add("active")
              link.classList.add("active")
              // link.style.height = childHeight + "px"
              this.overlayMenu.parentNode.classList.add("active--token")
            }
          }
        })
      }
    }
  }
  //
  // appendContent = (content) => {
  //
  // }

  openOverlay = (level) => {
    document.body.dataset.overlay = true
    document.body.dataset.overlayLevel = level
    toggleHelpButton(true, this.helpButton)
    $("[data-toggle]").classList.remove("toggle--collapsed")
  }

  close = () => {
    // show overlay link again
    document.body.dataset.overlay = false
    document.body.dataset.overlayLevel = null
    toggleHelpButton(false, this.helpButton)
  }

  changeLevel = () => {
    $("[data-toggle]").classList.remove("toggle--collapsed")
    for (const child of this.children) {
      child.classList.remove("active")
    }
    for (const child of this.overlayMenuParent) {
      child.classList.remove("active")
    }
    this.overlayMenu.parentNode.classList.remove("active--token")
    if (this.activeLevel === "first") {
      // hide overlay link
      document.body.dataset.overlayLevel = "second"
      this.activeLevel = "second"
    } else {
      // show overlay link again
      document.body.dataset.overlayLevel = "first"
      this.activeLevel = "first"
    }

    // document.body.dataset.overlay = false
  }
}
